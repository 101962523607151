/* eslint-disable */
import React, { useEffect, useMemo, useState } from 'react'
import SelectReact from './SelectReact'
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import FrameScanner from "../Common/FrameScanner";

const FrameItems = (props) =>{
    const {options, frameState, item, index, index1, SelectList, MainOptions } = props;
    const [ isScan, setisScan ] = useState(false);
    const [ FrameScanningModal, setFrameScanningModal ] = useState({
        open: false,
        option_list: [],
        id_frmaes: {
            id: "",
            frame_numbs: ""
        },
    });

    const getOption = () =>{
        // eslint-disable-next-line
        const data = SelectList?.find((item, index2)=> item?.index == index1);

        if(data !== undefined){
            return data?.value;
        }else{
            return null;
        }
    };
    
    const onChange = (e) =>{
        // eslint-disable-next-line
        props?.onChange(index1, e.target.value);
    }

    const onChangeFrameSelection = (inedex_of_frame, frame_id) => {
        // eslint-disable-next-line
        props?.onChange(inedex_of_frame, frame_id);
    };

    const onChangeAccessories = (e) =>{
        // eslint-disable-next-line
        props?.onChange(index1, e.target.value);
    };

    const getValue = SelectList[0];
    // eslint-disable-next-line
    // const getSelect = useMemo(getOption, [SelectList, index]);
    const getSelect = useMemo(() => {
        return getOption(SelectList, index);
    }, [SelectList, index]);
    
    const getSelect2 = options?.option1?.find((item)=>item?.value == getSelect);

    const QRCodeModal = () => {
        setFrameScanningModal({
            ...FrameScanningModal,
            open: true,
            option_list: options?.option,
            id_frmaes: {
                id: index1,
                frame_numbs: ""
            },
        });
    };
    
    if(frameState){
        return(<React.Fragment>
            <tr >
                {/* <td style={{ padding: "7px 7px", fontSize: "14px" }}>
                    <span className={`numbering ${index + 1}`}> {index + 1}</span>
                </td> */}
                {/* <td style={{ padding: "7px 7px", fontSize: "14px" }}>{item?.product_code}</td> */}
                <td style={{ padding: "7px 7px", fontSize: "14px" }}>{item?.product_name}</td>
                <td style={{ padding: "7px 7px", fontSize: "14px" }}>
                    <SelectReact 
                        options={options?.option} 
                        value={getSelect} 
                        onChange={onChange} 
                        isScan={isScan}
                        setisScan={setisScan}
                        MainOptions={MainOptions}
                    />
                </td>
                <td>
                    {getSelect === null ? (<Button className='qr_scanning_btn' onClick={() => QRCodeModal()}>
                        <i className="bi bi-upc-scan"></i>
                    </Button>) : "-"}
                </td>
                {/* <td style={{ padding: "7px 7px", fontSize: "14px" }}>
                    {getSelect2?.battery_number}
                </td>
                <td style={{ padding: "7px 7px", fontSize: "14px" }}>
                    {getSelect2?.motor_number}
                </td>
                <td style={{ padding: "7px 7px", fontSize: "14px" }}>
                    {getSelect2?.charger_number}
                </td> */}
            </tr>
            {FrameScanningModal?.open && (<FrameScanner 
                FrameScanningModal={FrameScanningModal} 
                setFrameScanningModal={setFrameScanningModal} 
                setisScan={setisScan}
                onChangeFrameSelection={onChangeFrameSelection} 
            />)}
        </React.Fragment>)
    }
    return(
        <tr >
            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{index + 1}</td>
            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{item?.product_code}</td>
            <td style={{ padding: "7px 7px", fontSize: "14px" }}>{item?.product_name}</td>
            <td style={{ padding: "7px 7px", fontSize: "14px" }}>
                {/* <SelectReact options={options?.option} value={getSelect} onChange={onChange} /> */}
                <Form.Control as="textarea"  onChange={onChangeAccessories} value={getValue?.remark} maxLength={200}></Form.Control>
            </td>
        </tr>
    )
}
export default function FrameSelectionItem(props) {
    const { OptionList } = useSelector((state) => state.adminReducers);
    const {item, index, warehouse_id, FrameScanningModal, setFrameScanningModal, isScan, setisScan} = props;
    const [SelectList, setSelectListItem] = useState([]);
    const rangeArray = Array.from({ length: item?.qty }, (_, index) => index);

    const getOption = () =>{
        if(OptionList){
            const data = OptionList[`${warehouse_id}_${item?.product_id}`];
            if(data){
                // const myDataOption = data?.data?.filter((item)=>{
                //     const checkExist = SelectList?.find((item1)=>item1?.value === item?.id);
                //     if(checkExist){
                //         return false
                //     }else{
                //         return true
                //     }
                // });
                const selectedIds = SelectList.map(item => item.value);

                const myDataOption = data?.data?.filter(item => !selectedIds.includes(item.id));

                return {
                    ...data,
                    option:myDataOption?.map((item)=>({...item, label:item?.serial_number || item?.frame_number, value: item?.id})),
                    option1:data?.data?.map((item)=>({...item, label:item?.serial_number || item?.frame_number, value: item?.id})),
                }
            }else{
                return null;
            }
        }else{
            return null;
        }
    }

    const [options, setOptions] = useState([])
    useEffect(()=>{
        const data = getOption();
        setOptions(data);
            // eslint-disable-next-line
    },[SelectList, OptionList, OptionList && ( OptionList[`${warehouse_id}_${item?.product_id}`] )])

    const onChange = (ind, item_value) =>{
        const oldList = [...SelectList];
        // eslint-disable-next-line
        const data = SelectList?.find((item1, index2)=> item1?.index == ind);
        
        if(!data){
            oldList.push({index: ind, value:item_value});
            setSelectListItem(oldList);
            const Data = oldList?.map((item)=>item?.value);
            props?.callBack(Data, item?.id)
        } else{
            const data = SelectList?.map((item1, index2)=> {
                // eslint-disable-next-line
                if(item1?.index == ind){
                    return {
                        ...item1,
                        value:item_value
                    }
                }
                return item1
            });
            setSelectListItem(data); 
            const Data = data?.map((item)=>item?.value);
            props?.callBack(Data, item?.id)
        }
    };

    const onChangeAccessories =(text, value) =>{
            const Data = [value];
            setSelectListItem(Data)
            props?.callBack(Data, item?.id, "Accessories")
    };

    if(props?.frameState){
        return(
           <React.Fragment>
                {rangeArray?.map((item1, index1)=>(
                    <React.Fragment key={index1}>
                        <FrameItems 
                            item={item} 
                            options={options}  
                            index1={`${item?.id}_${index1}`} 
                            itemId={item} 
                            onChange={onChange} 
                            SelectList={SelectList} 
                            frameState={props?.frameState} 
                            index={index1} 
                            MainOptions={OptionList[`${warehouse_id}_${item?.product_id}`]?.data?.map((item)=>({...item, label:item?.serial_number || item?.frame_number, value: item?.id}))}
                            // FrameScanningModal={FrameScanningModal} 
                            // setFrameScanningModal={setFrameScanningModal}
                            // isScan={isScan}
                            // setisScan={setisScan}
                        />
                    </React.Fragment>
                ))}
           </React.Fragment>
        )
    }
  return (
    <React.Fragment>
        {/* {rangeArray?.map((item1, index1)=>( */}
            <React.Fragment >
                <FrameItems item={item} options={options} index1={`${item?.id}_${index}`} onChange={onChangeAccessories}  SelectList={SelectList} frameState={props?.frameState} index={index} />
            </React.Fragment>
        {/* ))} */}
    </React.Fragment>
  )
}
FrameSelectionItem.propTypes = {
    frameState: PropTypes.bool,
    callBack: PropTypes.func,
}
FrameSelectionItem.defaultProps = {
    frameState: false,
    callBack: ()=>{}
}