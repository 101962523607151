/* eslint-disable */
import React, { useEffect, useState, createContext } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import "./assets/css/style.css";
import "./assets/css/media.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { LOGIN_URL, DASHBOARD_URL, PRODUCT_APPLICATIONS_URL, ACCOUNT_URL, PURCHASE_LIST_URL, CATEGORY_URL, SUB_CATEGORY_URL, PRODUCT_CREATION_URL, CREATE_PRODUCT_APLICATION_URL, RETURN_AND_REPLACE_REPORT, CREATE_CATEGORY_URL, CREATE_SUB_CATEGORY_URL, CREATE_PRODUCT_URL, FRANCHISE_USER_REPORT_URL, CREATE_FRANCHISE_USER_REPORT_URL, STOCK_INWARD_URL, STOCK_LEVEL_URL, STORE_DETAILS_URL, STORE_INWARD_LOG_URL, STORE_DETAILS_REPORT_URL, FRANCHISE_REPORT_DETAILS_URL, STOCK_TRANSFER_URL, WAREHOUSE_URL, CREATE_WAREHOUSE_URL, WAREHOUSE_QUICK_URL, SET_LOGIN_URL,SET_WM_LOGIN_URL,SET_RM_LOGIN_URL ,FRANCHISE_ORDERSREPORT_URL, E_WAY_BILL_URL, FRANCHISE_STOCK_LEVEL_URL, E_INVOICE_CANCEL_URL, E_INVOICE_DETAILS_URL, ORDER_CANCELLED_URL, PUR_INVOICE_DETAILS_URL, PUR_ORDER_DELIVERED_URL, FRANCHISE_PENDING_DELIVERY_URL, FRANCHISE_DISPATCH_PENDING_URL, FRANCHISE_DISPATCH_URL, FRANCHISE_PENDING_ORDERS_URL, PO_APPROVALS_URL, PO_POSTING_URL, PO_PURCHASE_ORDER_URL, USER_MANAGEMENT_URL, REGION_MASTER_URL, RM_LOGIN_URL,ADD_REGION_MASTER_URL, CITIES_URL, ADD_CITIES_URL, STATES_URL, ADD_STATES_URL, PINE_CODES_URL, CREATE_USER_MANAGEMENT_URL, FRANCHISE_TYPE_REPORT_URL, ADD_FRANCHISE_TYPE_REPORT_URL, PRODUCT_GROUP_URL, CREATE_PRODUCT_GROUP_URL, EDIT_PRODUCT_GROUP_URL, PURCHASE_ORDER_URL, DISPATCH_LIST_URL, CREATE_DISPATCH_LIST_URL, COURIERS_URL, UPDATE_CITIES_URL, BOX_URL, DEBIT_CREDIT_NOTES, DEBIT_CREDIT_NOTES_DETAIL_PAGE, ACCOUNTLEDGERREPORT, ACCOUNT_LEDGER_REPORT, GSTDATAREPORT, GST_DATA_REPORT, OUT_STANDING_DATA_REPORT, DEBIT_CREDIT_NOTE_REPORT, DEPOSIT_REPORT, PAYMENT_APPROVAL, CREDIT_NOTE_REQUEST, CREDIT_NOTE_REPORT, RETURN_AND_REPLACE_REPORT_LIST, ADD_PINCODES_URL, FRANCHISE_USER_REPORT_BRAND } from './components/Shared/constant';
import AuthLayout from './components/Shared/AuthLayout';
import Dashboard from "./components/Dashboard";
import Productapplications from './components/Inventory/ProductMasters/Productapplications';
import Category from './components/Inventory/ProductMasters/Category';
import SubCategory from './components/Inventory/ProductMasters/SubCategory';
import Productcreation from './components/Inventory/ProductMasters/Productcreation';
import CreateApplications from './components/Inventory/EditInventory/CreateApplications';
import CreateCategory from './components/Inventory/EditInventory/CreateCategory';
import SubCreateCategory from './components/Inventory/EditInventory/SubCreateCategory';
import CreateProduct from './components/Inventory/EditInventory/CreateProduct';
import FranchiseUserReport from './components/Franchise/Franchise/FranchiseUserReport';
import CreateFranchiseUserReport from './components/Franchise/EditFranchise/CreateFranchiseUserReport';
import StockInward from './components/Inventory/Stores/StockInward';
import StockLevel from './components/Inventory/Stores/StockLevel';
import GuestLayout from './components/Shared/GuestLayout';
import Login from './components/Login';
import StoreDetails from './components/Inventory/Stores/StoreDetails';
import StoreDetailsReport from './components/Inventory/Stores/StoreDetailsReport';
import FranchiseStoreDetails from './components/Inventory/Stores/FranchiseStoreDetails';
import StockTransfer from './components/Inventory/StockTransfer/StockTransfer';
import Warehouse from './components/Inventory/Warehouse/Warehouse';
import CreateWarehouse from './components/Inventory/EditInventory/CreateWarehouse';
import WQuickLogin from './components/Inventory/Warehouse/WQuickLogin';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import useWebSocket from './Api/UseWebsocket';
import SetLogin from './components/SetLogin';
import { getAccessToken, SelectMainNavigation, GetOrderReportDetails, clearRedux } from './redux/actions/adminActions';
import FranchiseOrdersReport from './components/Franchise/Franchise/FranchiseOrdersReport';
import EWayBillReport from './components/Franchise/Franchise/EWayBillReport';
import FranchiseStockLevel from './components/Franchise/Franchise/FranchiseStockLevel';
import EInvoiceCancel from './components/Franchise/Franchise/EInvoiceCancel';
import EInvoiceDetails from './components/Franchise/Franchise/EInvoiceDetails';
import PurOrderRejected from './components/Franchise/Franchise/PurOrderRejected';
import PurOrderDelivered from './components/Franchise/Franchise/PurOrderDelivered';
import FranchisePendingDelivery from './components/Franchise/Franchise/FranchisePendingDelivery';
import FranchisePendingDeliveryEdit from './components/Franchise/Franchise/FranchisePendingDeliveryEdit';
import FranchiseDispatchShipping from './components/Franchise/Franchise/FranchiseDispatchShipping';
import FranchiseDispatch from './components/Franchise/Franchise/FranchiseDispatch';
import FranchisePendingOrders from './components/Franchise/Franchise/FranchisePendingOrders';
import POApprovals from './components/Franchise/Franchise/POApprovals';
import POPosting from './components/Franchise/Franchise/POPosting';
import POPurchaseOrder from './components/Franchise/Franchise/POPurchaseOrder';
import User from './components/AdminTools/User';
import RegionMaster from './components/CRM/RegionMaster';
import AddRegionMaster from './components/CRM/Edit/AddRegionMaster';
import Cities from './components/AdminTools/Masters/Cities';
import AddCities from './components/AdminTools/EditMaster/AddCities';
import States from './components/AdminTools/Masters/States';
import AddStates from './components/AdminTools/EditMaster/AddStates';
import Pincodes from './components/AdminTools/Masters/Pincodes';
import AddUser from './components/AdminTools/User/AddUser';
// import CommonLogout from './components/Common/CommonLogout';
import FranchiseType from './components/CRM/FranchiseType';
import AddFranchiseType from './components/CRM/Edit/AddFranchiseType';
import ProductGroup from './components/Inventory/ProductMasters/ProductGroup';
import CreateProductGroup from './components/Inventory/EditInventory/CreateProductGroup';
import PurchaseOrder from './components/Franchise/Franchise/PurchaseOrder';
import ApprovalOrders from './components/Franchise/EditFranchise/ApprovalOrders';
import WarehouseApprovalOrders from './components/Franchise/EditFranchise/WarehouseApprovalOrders';
import DispatchList from './components/Franchise/Franchise/DispatchList';
import CreateDispatchList from './components/Franchise/EditFranchise/CreateDispatchList';
import FranchiseDispatchEdit from './components/Franchise/Franchise/FranchiseDispatchEdit';
import FranchisePendingOrdersEdit from './components/Franchise/Franchise/FranchisePendingOrdersEdit';
import CouriersListPage from './components/AdminTools/Masters/CouriersListPage';
import AddCourier from './components/AdminTools/EditMaster/AddCourier';
import FranchiseDispatchShippingEdit from './components/Franchise/Franchise/FranchiseDispatchShippingEdit';
import PageLoad from './PageLoad';
import ShowInvoiceModal from './components/Common/ShowInvoiceModal';
import SOCancelled from './components/Franchise/Franchise/SOCancelled';
import Box from './components/AdminTools/Masters/Box';
import WMLogin from './components/WMLogin';
import RmQuickLogin from './components/AdminTools/RmLogin/RmQuickLogin';
import RmLogin from './components/RmLogin';
import DebitCreditNotes from './components/Franchise/Payment/DebitCreditNotes';
import DebitCreditNotesDetailPage from './components/Franchise/Payment/DebitCreditNotesDetailPage';
import AccountLedgerReport from './components/Franchise/Payment/AccountLedgerReport';
import GstDataReport from './components/Sales/Report/GstDataReport';
import OutStandingReport from './components/Sales/Report/OutStandingReport';
import DebitCreditNoteReport from './components/Franchise/Payment/DebitCreditNoteReport';
import DepositReport from './components/Franchise/Payment/DepositReport';
import PaymentApproval from './components/Franchise/Payment/PaymentApproval';
import CreditNoteRequest from './components/Franchise/Payment/CreditNoteRequest';
import CreditNoteReport from './components/Franchise/Payment/CreditNoteReport';
import ReturnAndReplace from './components/Franchise/Franchise/ReturnAndReplace';
import AddReturnAndReplace from './components/Franchise/Franchise/AddReturnAndReplace';
import PurchaseList from './components/CRM/PurchaseList';
import EditPurchase from './components/CRM/Edit/EditPurchase';
import AddPincodes from './components/AdminTools/EditMaster/AddPincodes';
import StockInwardLog from './components/Inventory/Stores/StockInwardLog';
import CreateFranchiseBrand from './components/Franchise/Franchise/CreateFranchiseBrand';
import { Button } from 'react-bootstrap';
import Accounts from './components/AdminTools/Accounts';
export const WebSocketContext = createContext();
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

function App() {
  const Dispatch = useDispatch();
  const { accessToken, MainNavUrl, selfInfo, ClearCachenew__ } = useSelector((state) => state.adminReducers);
  const [ GetWEBSocket, setGetWEBSocket ] = useState("");
  const PermissionsAccess = selfInfo?.user?.permissions_access;
  const [ isOnline, setIsOnline ] = useState(navigator.onLine);
  const [ WebsocketCheck, setWebsocketCheck ] = useState("");
  const [ width, setWidth ] = useState(window.innerWidth);

  // websocket connection
  useWebSocket(`${process.env.REACT_APP_SERVICE_URL}/${localStorage.getItem("access_token")}/`, setGetWEBSocket, localStorage.getItem("access_token"), setWebsocketCheck);

  useEffect(() => {
    if(localStorage.getItem("access_token") === null) {
       Dispatch(getAccessToken(""));
    } else {
      Dispatch(getAccessToken(localStorage.getItem("access_token")));
    };
  }, []);

  useEffect(() => {
    if(MainNavUrl === "") {
        if(PermissionsAccess === null) {
          Dispatch(SelectMainNavigation("Franchise"));
        } else if(PermissionsAccess?.ProductMasters?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.Store?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.Warehouse?.filter((elm) => elm?.API !== "")?.length > 0) {
          Dispatch(SelectMainNavigation("Inventory"));
        } else if(PermissionsAccess?.AdminTools?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.Masters?.filter((elm) => elm?.API !== "")?.length > 0) {
          Dispatch(SelectMainNavigation("Admin-Tools"));
        } else if(PermissionsAccess?.OrderManagement?.filter((elm) => elm?.API !== "")?.length > 0 || PermissionsAccess?.PaymentManager?.filter((elm) => elm?.API !== "")?.length > 0) {
          Dispatch(SelectMainNavigation("Franchise"));
        } else if(PermissionsAccess?.CRMTools?.filter((elm) => elm?.API !== "")?.length > 0) {
          Dispatch(SelectMainNavigation("CRM-Tools"));
        } else {
          // Dispatch(SelectMainNavigation("Inventory"));
        }
    } else {
      
    };
  }, [  ]);

  // internet check
  useEffect(() => {
      const handleOnline = () => setIsOnline(true);
      const handleOffline = () => setIsOnline(false);

      window.addEventListener('online', handleOnline);
      window.addEventListener('offline', handleOffline);

      return () => {
          window.removeEventListener('online', handleOnline);
          window.removeEventListener('offline', handleOffline);
      };
  }, []);

  // get current responsive width
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    
    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if(ClearCachenew__ === undefined || ClearCachenew__ === null) {
        Dispatch(clearRedux());
        localStorage?.removeItem("access_token");
    };
  }, [ ClearCachenew__ ]);

  return (<WebSocketContext.Provider value={{websocket:GetWEBSocket, ResponsiveWidth: width}}>
    <div className="wrapper">
        <BrowserRouter>
          <PageLoad state={false}/>
          <Routes>
            {/* <Route path={SET_LOGIN_URL} element={<PageLoad state={true}/>} /> */}
            <Route path={`${SET_RM_LOGIN_URL}`} element={<RmLogin />} />
            <Route path={`${SET_WM_LOGIN_URL}`} element={<WMLogin />} />
            <Route path={SET_LOGIN_URL} element={accessToken === "" ? <Navigate  replace to={LOGIN_URL}/> : <SetLogin />} />
            <Route path="/" element={accessToken === "" ? <Login /> : <Navigate  replace to={DASHBOARD_URL}/>} >
                <Route path={LOGIN_URL} element={accessToken === "" ? <Login /> : <Navigate  replace to={DASHBOARD_URL}/>} />
            </Route>
            <Route path={DASHBOARD_URL} element={accessToken !== "" ? <AuthLayout><Dashboard /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={PRODUCT_APPLICATIONS_URL} element={accessToken !== "" ? <AuthLayout><Productapplications RouteName={PRODUCT_APPLICATIONS_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={CREATE_PRODUCT_APLICATION_URL} element={accessToken !== "" ? <AuthLayout><CreateApplications /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={'/edit-product-application/:roomId'} element={accessToken !== "" ? <AuthLayout><CreateApplications /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={CATEGORY_URL} element={accessToken !== "" ? <AuthLayout><Category RouteName={CATEGORY_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={CREATE_CATEGORY_URL} element={accessToken !== "" ? <AuthLayout><CreateCategory RouteName={CREATE_CATEGORY_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={'/edit-category/:roomId'} element={accessToken !== "" ? <AuthLayout><CreateCategory /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={SUB_CATEGORY_URL} element={accessToken !== "" ? <AuthLayout><SubCategory RouteName={SUB_CATEGORY_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={CREATE_SUB_CATEGORY_URL} element={accessToken !== "" ? <AuthLayout><SubCreateCategory RouteName={CREATE_SUB_CATEGORY_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={'/edit-subcategory/:roomId'} element={accessToken !== "" ? <AuthLayout><SubCreateCategory RouteName={CREATE_SUB_CATEGORY_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={PRODUCT_CREATION_URL} element={accessToken !== "" ? <AuthLayout><Productcreation RouteName={PRODUCT_CREATION_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={CREATE_PRODUCT_URL} element={accessToken !== "" ? <AuthLayout><CreateProduct RouteName={CREATE_PRODUCT_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={'/edit-product/:roomId'} element={accessToken !== "" ? <AuthLayout><CreateProduct RouteName={CREATE_PRODUCT_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={PRODUCT_GROUP_URL} element={accessToken !== "" ? <AuthLayout><ProductGroup RouteName={PRODUCT_GROUP_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={CREATE_PRODUCT_GROUP_URL} element={accessToken !== "" ? <AuthLayout><CreateProductGroup RouteName={CREATE_PRODUCT_GROUP_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={'/edit-product-group/:roomId'} element={accessToken !== "" ? <AuthLayout><CreateProductGroup RouteName={CREATE_PRODUCT_GROUP_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={STOCK_INWARD_URL} element={accessToken !== "" ? <AuthLayout><StockInward RouteName={STOCK_INWARD_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={STOCK_LEVEL_URL} element={accessToken !== "" ? <AuthLayout><StockLevel RouteName={STOCK_LEVEL_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={STORE_DETAILS_URL} element={accessToken !== "" ? <AuthLayout><StoreDetails RouteName={STORE_DETAILS_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={STORE_INWARD_LOG_URL} element={accessToken !== "" ? <AuthLayout><StockInwardLog RouteName={STORE_INWARD_LOG_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={STORE_DETAILS_REPORT_URL} element={accessToken !== "" ? <AuthLayout><StoreDetailsReport RouteName={STORE_DETAILS_REPORT_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={FRANCHISE_REPORT_DETAILS_URL} element={accessToken !== "" ? <AuthLayout><FranchiseStoreDetails RouteName={FRANCHISE_REPORT_DETAILS_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={STOCK_TRANSFER_URL} element={accessToken !== "" ? <AuthLayout><StockTransfer RouteName={STOCK_TRANSFER_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={WAREHOUSE_URL} element={accessToken !== "" ? <AuthLayout><Warehouse RouteName={WAREHOUSE_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={CREATE_WAREHOUSE_URL} element={accessToken !== "" ? <AuthLayout><CreateWarehouse RouteName={CREATE_WAREHOUSE_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={'/edit-warehouse/:roomId'} element={accessToken !== "" ? <AuthLayout><CreateWarehouse RouteName={CREATE_WAREHOUSE_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={WAREHOUSE_QUICK_URL} element={accessToken !== "" ? <AuthLayout><WQuickLogin RouteName={WAREHOUSE_QUICK_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={FRANCHISE_ORDERSREPORT_URL} element={accessToken !== "" ? <AuthLayout><FranchiseOrdersReport RouteName={FRANCHISE_ORDERSREPORT_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={E_WAY_BILL_URL} element={accessToken !== "" ? <AuthLayout><EWayBillReport RouteName={E_WAY_BILL_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={FRANCHISE_STOCK_LEVEL_URL} element={accessToken !== "" ? <AuthLayout><FranchiseStockLevel RouteName={FRANCHISE_STOCK_LEVEL_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={E_INVOICE_CANCEL_URL} element={accessToken !== "" ? <AuthLayout><EInvoiceCancel RouteName={E_INVOICE_CANCEL_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={E_INVOICE_DETAILS_URL} element={accessToken !== "" ? <AuthLayout><EInvoiceDetails RouteName={E_INVOICE_DETAILS_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={PUR_INVOICE_DETAILS_URL} element={accessToken !== "" ? <AuthLayout><PurOrderRejected RouteName={PUR_INVOICE_DETAILS_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={ORDER_CANCELLED_URL} element={accessToken !== "" ? <AuthLayout><SOCancelled RouteName={ORDER_CANCELLED_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={PUR_ORDER_DELIVERED_URL} element={accessToken !== "" ? <AuthLayout><PurOrderDelivered RouteName={PUR_ORDER_DELIVERED_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={FRANCHISE_PENDING_DELIVERY_URL} element={accessToken !== "" ? <AuthLayout><FranchisePendingDelivery RouteName={FRANCHISE_PENDING_DELIVERY_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={`${FRANCHISE_PENDING_DELIVERY_URL}/:id`} element={accessToken !== "" ? <AuthLayout><FranchisePendingDeliveryEdit RouteName={FRANCHISE_PENDING_DELIVERY_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={FRANCHISE_DISPATCH_PENDING_URL} element={accessToken !== "" ? <AuthLayout><FranchiseDispatchShipping RouteName={FRANCHISE_DISPATCH_PENDING_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={`${FRANCHISE_DISPATCH_PENDING_URL}/:id`} element={accessToken !== "" ? <AuthLayout><FranchiseDispatchShippingEdit RouteName={FRANCHISE_DISPATCH_PENDING_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={FRANCHISE_DISPATCH_URL} element={accessToken !== "" ? <AuthLayout><FranchiseDispatch RouteName={FRANCHISE_DISPATCH_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={`${FRANCHISE_DISPATCH_URL}/:id`} element={accessToken !== "" ? <AuthLayout><FranchiseDispatchEdit RouteName={FRANCHISE_DISPATCH_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={FRANCHISE_PENDING_ORDERS_URL} element={accessToken !== "" ? <AuthLayout><FranchisePendingOrders RouteName={FRANCHISE_PENDING_ORDERS_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={`${FRANCHISE_PENDING_ORDERS_URL}/:id`} element={accessToken !== "" ? <AuthLayout><FranchisePendingOrdersEdit RouteName={FRANCHISE_PENDING_ORDERS_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={'/so-approval/:roomId'} element={accessToken !== "" ? <AuthLayout><ApprovalOrders RouteName={PO_APPROVALS_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={PO_POSTING_URL} element={accessToken !== "" ? <AuthLayout><POPosting RouteName={PO_POSTING_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={PO_APPROVALS_URL} element={accessToken !== "" ? <AuthLayout><POApprovals RouteName={PO_APPROVALS_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={`${PO_APPROVALS_URL}/:roomId`} element={accessToken !== "" ? <AuthLayout><WarehouseApprovalOrders RouteName={PO_APPROVALS_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={PO_PURCHASE_ORDER_URL} element={accessToken !== "" ? <AuthLayout><POPurchaseOrder RouteName={PO_PURCHASE_ORDER_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={USER_MANAGEMENT_URL} element={accessToken !== "" ? <AuthLayout><User RouteName={USER_MANAGEMENT_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={RM_LOGIN_URL} element={accessToken !== "" ? <AuthLayout><RmQuickLogin RouteName={RM_LOGIN_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={CREATE_USER_MANAGEMENT_URL} element={accessToken !== "" ? <AuthLayout><AddUser RouteName={CREATE_USER_MANAGEMENT_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={ACCOUNT_URL} element={accessToken !== "" ? <AuthLayout><Accounts RouteName={ACCOUNT_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={'/edit-user/:roomId'} element={accessToken !== "" ? <AuthLayout><AddUser RouteName={CREATE_PRODUCT_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={REGION_MASTER_URL} element={accessToken !== "" ? <AuthLayout><RegionMaster RouteName={REGION_MASTER_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={ADD_REGION_MASTER_URL} element={accessToken !== "" ? <AuthLayout><AddRegionMaster RouteName={ADD_REGION_MASTER_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={'/edit-region-master/:roomId'} element={accessToken !== "" ? <AuthLayout><AddRegionMaster RouteName={CREATE_PRODUCT_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={CITIES_URL} element={accessToken !== "" ? <AuthLayout><Cities RouteName={CITIES_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={ADD_CITIES_URL} element={accessToken !== "" ? <AuthLayout><AddCities RouteName={ADD_CITIES_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={`${UPDATE_CITIES_URL}/:id`} element={accessToken !== "" ? <AuthLayout><AddCities RouteName={ADD_CITIES_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={COURIERS_URL} element={accessToken !== "" ? <AuthLayout><CouriersListPage RouteName={COURIERS_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={`${COURIERS_URL}/add`} element={accessToken !== "" ? <AuthLayout><AddCourier RouteName={COURIERS_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={`${COURIERS_URL}/:id`} element={accessToken !== "" ? <AuthLayout><AddCourier RouteName={COURIERS_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={STATES_URL} element={accessToken !== "" ? <AuthLayout><States RouteName={STATES_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={ADD_STATES_URL} element={accessToken !== "" ? <AuthLayout><AddStates RouteName={ADD_STATES_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={`edit-state/:id`} element={accessToken !== "" ? <AuthLayout><AddStates RouteName={ADD_STATES_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={PINE_CODES_URL} element={accessToken !== "" ? <AuthLayout><Pincodes RouteName={PINE_CODES_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={ADD_PINCODES_URL} element={accessToken !== "" ? <AuthLayout><AddPincodes RouteName={ADD_PINCODES_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            {/* <Route path={`edit-pincode/:id`} element={accessToken !== "" ? <AuthLayout><AddPincodes RouteName={ADD_PINCODES_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} /> */}
            <Route path={BOX_URL} element={accessToken !== "" ? <AuthLayout><Box RouteName={BOX_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={FRANCHISE_TYPE_REPORT_URL} element={accessToken !== "" ? <AuthLayout><FranchiseType RouteName={FRANCHISE_TYPE_REPORT_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={ADD_FRANCHISE_TYPE_REPORT_URL} element={accessToken !== "" ? <AuthLayout><AddFranchiseType RouteName={ADD_FRANCHISE_TYPE_REPORT_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={FRANCHISE_USER_REPORT_BRAND} element={accessToken !== "" ? <AuthLayout><CreateFranchiseBrand RouteName={FRANCHISE_USER_REPORT_BRAND} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={'/edit-franchise-type-report/:roomId'} element={accessToken !== "" ? <AuthLayout><AddFranchiseType RouteName={ADD_FRANCHISE_TYPE_REPORT_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={FRANCHISE_USER_REPORT_URL} element={accessToken !== "" ? <AuthLayout><FranchiseUserReport RouteName={FRANCHISE_USER_REPORT_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={CREATE_FRANCHISE_USER_REPORT_URL} element={accessToken !== "" ? <AuthLayout><CreateFranchiseUserReport RouteName={CREATE_FRANCHISE_USER_REPORT_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={'/edit-franchise-user-report/:roomId'} element={accessToken !== "" ? <AuthLayout><CreateFranchiseUserReport RouteName={CREATE_FRANCHISE_USER_REPORT_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={PURCHASE_ORDER_URL} element={accessToken !== "" ? <AuthLayout><PurchaseOrder RouteName={PURCHASE_ORDER_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={DISPATCH_LIST_URL} element={accessToken !== "" ? <AuthLayout><DispatchList RouteName={DISPATCH_LIST_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={CREATE_DISPATCH_LIST_URL} element={accessToken !== "" ? <AuthLayout><CreateDispatchList RouteName={CREATE_DISPATCH_LIST_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={'/edit-dispatch/:roomId'} element={accessToken !== "" ? <AuthLayout><CreateDispatchList RouteName={CREATE_DISPATCH_LIST_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            {/* payment */}
            <Route path={DEBIT_CREDIT_NOTES} element={accessToken !== "" ? <AuthLayout><DebitCreditNotes RouteName={DEBIT_CREDIT_NOTES} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            {/* <Route path={'/debit-credit-notes-detail:/id'} element={accessToken !== "" ? <AuthLayout><DebitCreditNotesDetailPage RouteName={"/debit-credit-notes-detail:/id"} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} /> */}
            <Route path={`${DEBIT_CREDIT_NOTES}/:roomId`} element={accessToken !== "" ? <AuthLayout><DebitCreditNotesDetailPage RouteName={DEBIT_CREDIT_NOTES} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={DEBIT_CREDIT_NOTE_REPORT} element={accessToken !== "" ? <AuthLayout><DebitCreditNoteReport RouteName={DEBIT_CREDIT_NOTE_REPORT} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={DEPOSIT_REPORT} element={accessToken !== "" ? <AuthLayout><DepositReport RouteName={DEPOSIT_REPORT} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={PAYMENT_APPROVAL} element={accessToken !== "" ? <AuthLayout><PaymentApproval RouteName={PAYMENT_APPROVAL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={CREDIT_NOTE_REQUEST} element={accessToken !== "" ? <AuthLayout><CreditNoteRequest RouteName={CREDIT_NOTE_REQUEST} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={CREDIT_NOTE_REPORT} element={accessToken !== "" ? <AuthLayout><CreditNoteReport RouteName={CREDIT_NOTE_REPORT} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={GST_DATA_REPORT} element={accessToken !== "" ? <AuthLayout><GstDataReport RouteName={GST_DATA_REPORT} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={OUT_STANDING_DATA_REPORT} element={accessToken !== "" ? <AuthLayout><OutStandingReport RouteName={OUT_STANDING_DATA_REPORT} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={ACCOUNT_LEDGER_REPORT} element={accessToken !== "" ? <AuthLayout><AccountLedgerReport RouteName={ACCOUNT_LEDGER_REPORT} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={RETURN_AND_REPLACE_REPORT_LIST} element={accessToken !== "" ? <AuthLayout><ReturnAndReplace RouteName={RETURN_AND_REPLACE_REPORT_LIST} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={RETURN_AND_REPLACE_REPORT} element={accessToken !== "" ? <AuthLayout><AddReturnAndReplace RouteName={RETURN_AND_REPLACE_REPORT} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={PURCHASE_LIST_URL} element={accessToken !== "" ? <AuthLayout><PurchaseList RouteName={PURCHASE_LIST_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path={`/edit-purchase/:roomId`} element={accessToken !== "" ? <AuthLayout><EditPurchase RouteName={PURCHASE_LIST_URL} /></AuthLayout> : <Navigate replace to={LOGIN_URL}/>} />
            <Route path="*" element={<Navigate replace to={accessToken === "" ? LOGIN_URL : SET_LOGIN_URL}/>} />
          </Routes>
          <ShowInvoiceModal/>
        </BrowserRouter>
        <ToastContainer />
    </div>
    {(accessToken !== "" && (!isOnline || WebsocketCheck === "websocket close")) && (<div className='notinternetFoundPage'>
        <div className='connectwrapp'>
           <i className="bi bi-wifi-off"></i>
           <h2>Whoops!!</h2>
           <p>{WebsocketCheck === "websocket close" ? "Disconnected from the server, please wait a few minutes." : "No internet connection was found. check your connection or try again."}</p>
           <Button onClick={() => window.location.reload()}>Try again</Button>
        </div>
    </div>)}
  </WebSocketContext.Provider>);
}

export default App;
