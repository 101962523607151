/* eslint-disable */
import React from "react";
import { Nav } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { FRANCHISE_ORDERSREPORT_URL, FRANCHISE_DISPATCH_URL, FRANCHISE_DISPATCH_PENDING_URL, FRANCHISE_PENDING_DELIVERY_URL, PURCHASE_LIST_URL, ACCOUNT_LEDGER_REPORT, STORE_DETAILS_REPORT_URL } from "../Shared/constant";

const ResponsiveSidebar = (props) => {
    const { MobileSidebar, setMobileSidebar } = props;
    const { selfInfo } = useSelector((state) => state.adminReducers);
    const LocationURL = useLocation().pathname;
    const LocationEditURL = LocationURL?.split("/")[1];
    const PermissionsAccess = selfInfo?.user?.permissions_access;
    
    return(<Nav defaultActiveKey={FRANCHISE_ORDERSREPORT_URL} className="flex-column responsiveSidebar">
        {MobileSidebar?.Url === "order_sidebar" ? (<ul>
            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[0]?.Edit || PermissionsAccess?.OrderManagement[0]?.View)) && (
                <li><Link className={LocationURL === FRANCHISE_ORDERSREPORT_URL ? "active" : ""} to={FRANCHISE_ORDERSREPORT_URL} onClick={() => setMobileSidebar(false)}>All Orders Report</Link></li>
            )}
            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[5]?.Edit || PermissionsAccess?.OrderManagement[5]?.View)) && (
                <li><NavLink className={LocationURL === FRANCHISE_DISPATCH_URL ? "active" : ""} to={FRANCHISE_DISPATCH_URL} onClick={() => setMobileSidebar(false)}>Pending For Stores</NavLink></li>
            )}
            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[6]?.Edit || PermissionsAccess?.OrderManagement[6]?.View)) && (
                <li><NavLink className={LocationURL === FRANCHISE_DISPATCH_PENDING_URL ? "active" : ""} to={FRANCHISE_DISPATCH_PENDING_URL} onClick={() => setMobileSidebar(false)}>Pending For Dispatch</NavLink></li>
            )}
            {/* {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.OrderManagement[7]?.Edit || PermissionsAccess?.OrderManagement[7]?.View)) && (
                <li><NavLink className={LocationURL === FRANCHISE_PENDING_DELIVERY_URL ? "active" : ""} to={FRANCHISE_PENDING_DELIVERY_URL} onClick={() => setMobileSidebar(false)}>Pending For Delivery</NavLink></li>
            )} */}
        </ul>) : (<ul>
            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.CRMTools[1]?.Edit || PermissionsAccess?.CRMTools[1]?.View)) && (<li>
                <Link className={(LocationURL === PURCHASE_LIST_URL || LocationEditURL === "edit-purchase") ? "active" : ""} to={PURCHASE_LIST_URL}>Purchase List</Link>
            </li>)}
            {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.Store[4]?.Edit || PermissionsAccess?.Store[4]?.View)) && (<li>
                <Link className={LocationURL === STORE_DETAILS_REPORT_URL ? "active" : ""} to={STORE_DETAILS_REPORT_URL} >Stock Details Report</Link>
            </li>)}
            {/* {(selfInfo?.user?.user_type === 'super_admin' || (PermissionsAccess?.PaymentManager[0]?.Edit || PermissionsAccess?.PaymentManager[0]?.View)) && (
                <li><Link className={LocationURL === ACCOUNT_LEDGER_REPORT ? "active" : ""} to={ACCOUNT_LEDGER_REPORT}>Account Ledger Report</Link></li>
            )} */}
        </ul>)}
    </Nav>)
}

export default ResponsiveSidebar;