/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Badge, Spinner, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { WAREHOUSE_URL, FILE_AUTH_URL } from "../../Shared/constant";
import { Scrollbars } from 'react-custom-scrollbars-2';
import EmptySpaceFieldValid from "../../Common/EmptySpaceFieldValid";
import CheckValid from "../../Common/CheckValid";
import { useSelector, useDispatch } from "react-redux";
import { WebSocketContext } from "../../../App";
import wsSend_request from "../../../Api/ws/ws_request";
import { StartCommonLoader, ErrorMessageFromRedux, SearchPincodeList } from "../../../redux/actions/adminActions";
import UploadFileAPI from "../../../Api/UploadFileAPI";
import FileAuthConvert from "../../../Api/FileAuthConvert";
import CommonToggle from "../../Common/CommonToggle";
import ProductDefaultImage from "../../../assets/img/DefaultProduct.jpg";
import Select from 'react-select'

const CreateWarehouse = (props) => {
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const { websocket } = useContext(WebSocketContext);
    const { ClearFormSet, searchPincodeList, device_id, commonLoader, wareHouseList, Regionlistall, accessToken, ErrorMessageFromReddux } = useSelector((state) => state.adminReducers);
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const [ ApplicationState, setApplicationState ] = useState({
        WarehouseName: "",
        DisplayName: "",
        PINCode: "",
        Address: "",
        PANCard: "",
        POCName: "",
        POCNumber: "",
        PANImage: "",
        GSTIN: "",
        GSTINImage: "",
        Region: "",
        Status: true,
        address2: "",
    });
    const { roomId } = useParams();
    const LocationRoute = useLocation()?.pathname?.split("/")[1];
    const CurrentData_ = wareHouseList?.list?.filter((elm) => elm?.id === roomId)[0];
    const [ optionsRegion, SetOptionsRegion ] = useState(()=>Regionlistall?.filter((elm) => elm?.is_active === true)?.map((item, index)=>({key:item.id, value:item?.id , label:item?.region_name })));
    let defaultRegionOption = optionsRegion?.filter((elm) => elm?.key === CurrentData_?.region_id)[0];
    const [ ImageDisplay, setImageDisplay ] = useState("");
    const [ GSTINImageDisplay, setGSTINImageDisplay ] = useState("");
    const pocRegex = /^\d{10}$/;
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];

    // error state
    const [ errorWarehouseName, seterrorWarehouseName ] = useState("");
    const [ errorDisplayName, seterrorDisplayName ] = useState("");
    const [ errorPINCode, seterrorPINCode ] = useState("");
    const [ errorAddress, seterrorAddress ] = useState("");
    const [ errorAddress2, seterrorAddress2 ] = useState("");
    const [ errorPANCard, seterrorPANCard ] = useState("");
    const [ errorGSTIN, seterrorGSTIN ] = useState("");
    const [ errorRegion, seterrorRegion ] = useState("");
    const [ errorBrandImage, seterrorBrandImage ] = useState("");
    const [ errorPOCName, seterrorPOCName ] = useState("");
    const [ errorPOCNumber, seterrorPOCNumber ] = useState("");

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };

    useEffect(() => {
        if(searchPincodeList) {
            setApplicationState({
                ...ApplicationState,
                PINCode: searchPincodeList?.pin?.pin_code === undefined ? "" : searchPincodeList?.pin?.pin_code,
            })
        };
    }, [ searchPincodeList ]);

    useEffect(() => {
        dispatch(StartCommonLoader(false))
        if(LocationRoute === "edit-warehouse") {
            const CurrentPincode = wareHouseList?.pin_code.filter((elm) => elm?.id === CurrentData_?.pin_code_id)[0]?.pin_code;
            setTimeout(() => {
                setApplicationState({
                    ...ApplicationState,
                    WarehouseName: CurrentData_?.warehouse_name,
                    DisplayName: CurrentData_?.display_name,
                    PINCode: CurrentPincode,
                    Address: CurrentData_?.address,
                    address2: CurrentData_?.address2 || "",
                    PANCard: CurrentData_?.pan_no === null ? "" : CurrentData_?.pan_no,
                    POCName: CurrentData_?.poc_name,
                    POCNumber: CurrentData_?.poc_number,
                    PANImage: CurrentData_?.pan_image,
                    GSTIN: CurrentData_?.gstin_no,
                    GSTINImage: CurrentData_?.gstin_image,
                    Region: CurrentData_?.region_id,
                    Status: CurrentData_?.is_active,
                });
                setImageDisplay({
                    url: FILE_AUTH_URL + CurrentData_?.pan_image_thumbnail_url,
                    name: CurrentData_?.pan_image_name
                });
                setGSTINImageDisplay({
                    url: FILE_AUTH_URL + CurrentData_?.gstin_image_thumbnail_url,
                    name: CurrentData_?.gstin_image_name
                });
                if(CurrentPincode?.length === 6) {
                    dispatch(StartCommonLoader(true));
                    let param = { 
                        "transmit": "broadcast", 
                        "url": "pin_code_search",
                        "request" : { 
                            "pin_code" : CurrentPincode, 
                        },
                        "DeviceId" : device_id
                    };
                    wsSend_request(websocket, param);
                } else {
                    dispatch(StartCommonLoader(false));
                }
            }, 500);
        };
    }, [ CurrentData_ ]);
    
    // search pincode
    const SearchPincode = () => {
        if(ApplicationState?.PINCode?.length === 6) {
            dispatch(StartCommonLoader(true));
            let param = { 
                "transmit": "broadcast", 
                "url": "pin_code_search",
                "request" : { 
                    "pin_code" : ApplicationState?.PINCode, 
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        } else {
            dispatch(StartCommonLoader(false));
        }
    };

    // check valid
    const GSTINvalid = (value) => {
        if(value !== "") {
            setApplicationState({...ApplicationState, GSTIN: value});
        } else {
            setApplicationState({...ApplicationState, GSTIN: ""});
            seterrorGSTIN("GSTIN cannot be empty");
        };
    };

    const GSTvalidations = (value) => {
        if(value !== "") {
            const GSTDigit = searchPincodeList?.state?.gst_code?.length === 1 ? 2 : searchPincodeList?.state?.gst_code?.length
            const GSTDigitValid = searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code;

            const Substring = value.trim().substring(0, GSTDigit);

            if(Substring.toString() === GSTDigitValid.toString()) {
                return true;
            } else {
                return false;
            }
        };
    };

    const GSTINvalidKeyUp = (value) => {
        if(value !== "") {
            if(searchPincodeList?.state?.gst_code === undefined) {
                seterrorGSTIN(`Before you enter the GST number, please enter the PIN code.`);
            } else {
                if(GSTvalidations(value) && value?.length === 15) {
                    seterrorGSTIN("");
                } else {
                    seterrorGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                };
            };
        } else {
            seterrorGSTIN("GSTIN cannot be empty");
        };
    };
            
    // select brand name
    const RegionSelectFCT = (value) => {
        if(value !== null) {
            setApplicationState({...ApplicationState, Region: value?.value});
            CheckValid(value, {type: 'RegionName', error: seterrorRegion});
        } else {
            setApplicationState({...ApplicationState, Region: ""});
            CheckValid(value === null ? "" : value, {type: 'RegionName', error: seterrorRegion});
        }
    };

    // search pincode
    const onEnterPincodeSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            SearchPincode();
        };
    };

    // upload pancard image 
    const UploadImage = async (e) => {
        setUploadFileLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await UploadFileAPI(accessToken, formData);
        if(fileresponse?.status === 200) {
            setApplicationState({...ApplicationState, PANImage: fileresponse?.id});
            const Authfileresponse = await FileAuthConvert(accessToken, FILE_AUTH_URL + fileresponse?.view_thumbnail_url);
            if(Authfileresponse?.status === 200) {
                setUploadFileLoader(false);
                setImageDisplay({
                    url: Authfileresponse?.authImage,
                    name: files.name
                });
                CheckValid(fileresponse?.id, {type: 'CategoryImage', error: seterrorBrandImage});
            } else {
                setUploadFileLoader(false);
            };
        } else {
            setUploadFileLoader(false);
        };
    };

    // upload GSTIN image 
    const UploadGSTINImage = async (e) => {
        setUploadFileLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await UploadFileAPI(accessToken, formData);
        if(fileresponse?.status === 200) {
            setApplicationState({...ApplicationState, GSTINImage: fileresponse?.id});
            const Authfileresponse = await FileAuthConvert(accessToken, FILE_AUTH_URL + fileresponse?.view_thumbnail_url);
            if(Authfileresponse?.status === 200) {
                setUploadFileLoader(false);
                setGSTINImageDisplay({
                    url: Authfileresponse?.authImage,
                    name: files.name
                });
                CheckValid(fileresponse?.id, {type: 'CategoryImage', error: seterrorBrandImage});
            } else {
                setUploadFileLoader(false);
            };
        } else {
            setUploadFileLoader(false);
        };
    };

    // remove image uploaded
    const RemoveUploadImage = () => {
        setApplicationState({...ApplicationState, PANImage: ""});
        setImageDisplay("");
    };

    // remove GSTIN image uploaded
    const RemoveGSTINImage = () => {
        setApplicationState({...ApplicationState, GSTINImage: ""});
        setGSTINImageDisplay("");
    };

    const PinCOdeSearchFunction = (e) => {
        setApplicationState({...ApplicationState, PINCode: e.target.value});
        if(e.target.value.length === 6 ){
                dispatch(StartCommonLoader(true));
                let param = { 
                    "transmit": "broadcast", 
                    "url": "pin_code_search",
                    "request" : { 
                        "pin_code" : e.target.value, 
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
            
        }if(e.target.value === "") {
            dispatch(SearchPincodeList({}));
            dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        }else{
            dispatch(StartCommonLoader(false))
        }
    };
   
    // pin code error get from redux
    useEffect(() => {
        if(ErrorMessageFromReddux?.API === "pin_code_search" && ErrorMessageFromReddux?.Error !== "") {
            seterrorPINCode(ErrorMessageFromReddux?.Error);
        };
    }, [ ErrorMessageFromReddux ]);

    // Warehouse create and update
    const CreateWareHouseFct = () => {
        if(ApplicationState?.PANCard === "") {
            if(ApplicationState?.WarehouseName.trim() !== "" && ApplicationState?.DisplayName.trim() !== "" && ApplicationState?.PINCode.trim() !== "" &&
                ApplicationState?.Address.trim() !== "" && ApplicationState?.address2?.length <= 100 && ApplicationState?.Address?.length <= 100 && ApplicationState?.GSTIN.trim() !== "" &&
                ApplicationState?.Region !== "" && ApplicationState?.POCNumber.trim() !== "" &&
                ApplicationState?.POCName.trim() !== ""
            ) {
                if(pocRegex.test(ApplicationState?.POCNumber.trim()) === true &&
                   (GSTvalidations(ApplicationState?.GSTIN) && ApplicationState?.GSTIN?.length === 15)
                ) {
                    if(LocationRoute === "edit-warehouse") {
                        let param = { 
                            "transmit": "broadcast", 
                            "url": "warehouse_edit",
                            "request" : { 
                                "warehouse_id" : CurrentData_?.id,
                                "warehouse_name" : ApplicationState?.WarehouseName.trim(), 
                                "display_name": ApplicationState?.DisplayName.trim(),
                                "pin_code_id": searchPincodeList?.pin?.id,
                                "address": ApplicationState?.Address.trim(),
                                "address2": ApplicationState?.address2?.trim(),
                                "poc_name": ApplicationState?.POCName,
                                "poc_number": ApplicationState?.POCNumber.trim(),
                                "pan_no": ApplicationState?.PANCard === null ? ApplicationState?.PANCard : ApplicationState?.PANCard.trim(), 
                                "pan_image": ApplicationState?.PANImage,
                                "gstin_no": ApplicationState?.GSTIN.trim(),
                                "gstin_image": ApplicationState?.GSTINImage,
                                "region_id": ApplicationState?.Region,
                                "is_active": ApplicationState?.Status,
                            },
                            "DeviceId" : device_id
                        };
                        wsSend_request(websocket, param);
                    } else {
                        let param = { 
                            "transmit": "broadcast", 
                            "url": "warehouse_add",
                            "request" : { 
                                "warehouse_name" : ApplicationState?.WarehouseName.trim(), 
                                "display_name": ApplicationState?.DisplayName.trim(),
                                "pin_code_id": searchPincodeList?.pin?.id,
                                "address": ApplicationState?.Address.trim(),
                                "address2": ApplicationState?.address2?.trim(),
                                "poc_name": ApplicationState?.POCName,
                                "poc_number": ApplicationState?.POCNumber.trim(),
                                "pan_no": ApplicationState?.PANCard === null ? ApplicationState?.PANCard : ApplicationState?.PANCard.trim(), 
                                "pan_image": ApplicationState?.PANImage,
                                "gstin_no": ApplicationState?.GSTIN.trim(),
                                "gstin_image": ApplicationState?.GSTINImage,
                                "region_id": ApplicationState?.Region,
                                "is_active": ApplicationState?.Status,
                            },
                            "DeviceId" : device_id
                        };
                        wsSend_request(websocket, param);
                    };
                } else {
                    CheckValid(ApplicationState?.POCNumber.trim(), {type: 'POCNumber', error: seterrorPOCNumber});
                    if(GSTvalidations(ApplicationState?.GSTIN) && ApplicationState?.GSTIN?.length === 15) {
                        seterrorGSTIN("");
                    } else {
                        seterrorGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                    };
                };
            } else{
                CheckValid(ApplicationState?.WarehouseName.trim(), {type: 'WarehouseName', error: seterrorWarehouseName});
                CheckValid(ApplicationState?.DisplayName.trim(), {type: 'DisplayName', error: seterrorDisplayName});
                CheckValid(ApplicationState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                CheckValid(ApplicationState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                CheckValid(ApplicationState?.POCNumber.trim(), {type: 'POCNumber', error: seterrorPOCNumber});
                CheckValid(ApplicationState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                if(ApplicationState?.Region === "") {
                    seterrorRegion("Please select region");
                } else {
                    seterrorRegion("");
                };

                if(ApplicationState?.GSTIN.trim() !== "") {
                    if(searchPincodeList?.state?.gst_code === undefined) {
                        seterrorGSTIN(`Before you enter the GSTIN number, please enter the PIN code.`);
                    } else {
                        if(GSTvalidations(ApplicationState?.GSTIN) && ApplicationState?.GSTIN?.length === 15) {
                            seterrorGSTIN("");
                        } else {
                            seterrorGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                        };
                    };
                } else {
                    seterrorGSTIN("GSTIN cannot be empty");
                };
            };
        } else {
            if(ApplicationState?.WarehouseName.trim() !== "" && ApplicationState?.DisplayName.trim() !== "" && ApplicationState?.PINCode.trim() !== "" &&
                ApplicationState?.Address.trim() !== "" &&  ApplicationState?.address2?.length <= 100 && ApplicationState?.Address?.length <= 100 && ApplicationState?.GSTIN.trim() !== "" &&
                ApplicationState?.Region !== "" && ApplicationState?.POCNumber.trim() !== "" &&
                ApplicationState?.POCName.trim() !== "" && ApplicationState?.PANCard !== ""
            ) {
                if(pocRegex.test(ApplicationState?.POCNumber.trim()) === true && panRegex.test(ApplicationState?.PANCard === null ? ApplicationState?.PANCard : ApplicationState?.PANCard.trim()) === true && ErrorMessageFromReddux?.Error === "" &&
                (GSTvalidations(ApplicationState?.GSTIN) && ApplicationState?.GSTIN?.length === 15)) {
                    if(LocationRoute === "edit-warehouse") {
                        let param = { 
                            "transmit": "broadcast", 
                            "url": "warehouse_edit",
                            "request" : { 
                                "warehouse_id" : CurrentData_?.id,
                                "warehouse_name" : ApplicationState?.WarehouseName.trim(), 
                                "display_name": ApplicationState?.DisplayName.trim(),
                                "pin_code_id": searchPincodeList?.pin?.id,
                                "address": ApplicationState?.Address.trim(),
                                "address2": ApplicationState?.address2?.trim(),
                                "poc_name": ApplicationState?.POCName,
                                "poc_number": ApplicationState?.POCNumber.trim(),
                                "pan_no": ApplicationState?.PANCard === null ? ApplicationState?.PANCard : ApplicationState?.PANCard.trim(), 
                                "pan_image": ApplicationState?.PANImage,
                                "gstin_no": ApplicationState?.GSTIN.trim(),
                                "gstin_image": ApplicationState?.GSTINImage,
                                "region_id": ApplicationState?.Region,
                                "is_active": ApplicationState?.Status,
                            },
                            "DeviceId" : device_id
                        };
                        wsSend_request(websocket, param);
                    } else {
                        let param = { 
                            "transmit": "broadcast", 
                            "url": "warehouse_add",
                            "request" : { 
                                "warehouse_name" : ApplicationState?.WarehouseName.trim(), 
                                "display_name": ApplicationState?.DisplayName.trim(),
                                "pin_code_id": searchPincodeList?.pin?.id,
                                "address": ApplicationState?.Address.trim(),
                                "address2": ApplicationState?.address2?.trim(),
                                "poc_name": ApplicationState?.POCName,
                                "poc_number": ApplicationState?.POCNumber.trim(),
                                "pan_no": ApplicationState?.PANCard === null ? ApplicationState?.PANCard : ApplicationState?.PANCard.trim(), 
                                "pan_image": ApplicationState?.PANImage,
                                "gstin_no": ApplicationState?.GSTIN.trim(),
                                "gstin_image": ApplicationState?.GSTINImage,
                                "region_id": ApplicationState?.Region,
                                "is_active": ApplicationState?.Status,
                            },
                            "DeviceId" : device_id
                        };
                        wsSend_request(websocket, param);
                    };
                } else {
                    CheckValid(ApplicationState?.POCNumber.trim(), {type: 'POCNumber', error: seterrorPOCNumber});
                    CheckValid(ApplicationState?.PANCard === null ? ApplicationState?.PANCard : ApplicationState?.PANCard.trim(), {type: 'PANCard', error: seterrorPANCard})

                    if(GSTvalidations(ApplicationState?.GSTIN) && ApplicationState?.GSTIN?.length === 15) {
                        seterrorGSTIN("");
                    } else {
                        seterrorGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                    };
                };
            } else{
                CheckValid(ApplicationState?.WarehouseName.trim(), {type: 'WarehouseName', error: seterrorWarehouseName});
                CheckValid(ApplicationState?.DisplayName.trim(), {type: 'DisplayName', error: seterrorDisplayName});
                CheckValid(ApplicationState?.PINCode.trim(), {type: 'PINCode', error: seterrorPINCode});
                CheckValid(ApplicationState?.Address.trim(), {type: 'Address', error: seterrorAddress});
                CheckValid(ApplicationState?.POCNumber.trim(), {type: 'POCNumber', error: seterrorPOCNumber});
                CheckValid(ApplicationState?.POCName.trim(), {type: 'POCName', error: seterrorPOCName});
                CheckValid(ApplicationState?.PANCard === null ? ApplicationState?.PANCard : ApplicationState?.PANCard.trim(), {type: 'PANCard', error: seterrorPANCard})
                if(ApplicationState?.Region === "") {
                    seterrorRegion("Please select region");
                } else {
                    seterrorRegion("");
                };

                if(ApplicationState?.GSTIN.trim() !== "") {
                    if(searchPincodeList?.state?.gst_code === undefined) {
                        seterrorGSTIN(`Before you enter the GSTIN number, please enter the PIN code.`);
                    } else {
                        if(GSTvalidations(ApplicationState?.GSTIN) && ApplicationState?.GSTIN?.length === 15) {
                            seterrorGSTIN("");
                        } else {
                            seterrorGSTIN(`Please enter a valid GSTIN number. GST numbers must begin with the prefix '${searchPincodeList?.state?.gst_code?.length === 1 ? String(searchPincodeList?.state?.gst_code).padStart(2, '0') : searchPincodeList?.state?.gst_code}'.`)
                        };
                    }
                } else {
                    seterrorGSTIN("GSTIN cannot be empty");
                };
            };
        };  
    };

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
          display: "none"
        };
        return <div style={{ ...style, ...trackStyle }} {...props} />;
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200) {
            Navigate(WAREHOUSE_URL);
            dispatch(SearchPincodeList({}));
            dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        };
    }, [ ClearFormSet ]);

    // clear data
    const ClearData = () => {
        dispatch(SearchPincodeList({}));
        dispatch(ErrorMessageFromRedux({
            API: "",
            Error: "",
        }));
    };

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    return(<section className="createApplcation_wrapper">
        <div className="MainHeader">
            <Link to={WAREHOUSE_URL} className="btn btn-link backbtn" onClick={() => ClearData()}>
                <i className="bi bi-arrow-left-short"></i> Back
            </Link>
        </div>
        <div className="tabsWrappersmAN">
            <Scrollbars 
                style={{ height: "calc(100vh - 147px)" }} 
                renderView={props => <div {...props} className="view"/>}
                renderTrackHorizontal={renderTrack}
                className="ScrollbarsSidebarHide"
            >
                <div className="formloginset">
                    <div className="row">
                        <div className="col-md-4">
                            <Form.Group className="mb-3 " >
                                <Form.Label>Warehouse Name<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={ApplicationState?.WarehouseName} 
                                    onChange={(e) => setApplicationState({...ApplicationState, WarehouseName: e.target.value})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'WarehouseName', error: seterrorWarehouseName})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    disabled={UploadFileLoader}
                                    placeholder="Enter Warehouse Name" 
                                />
                                {errorWarehouseName !== "" &&<span className="error_medotory">{errorWarehouseName}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Display Name<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={ApplicationState?.DisplayName} 
                                    onChange={(e) => setApplicationState({...ApplicationState, DisplayName: e.target.value})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'DisplayName', error: seterrorDisplayName})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    disabled={UploadFileLoader}
                                    placeholder="Enter Display Name" 
                                />
                                {errorDisplayName !== "" &&<span className="error_medotory">{errorDisplayName}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>
                                    PIN Code
                                    <span className="mendatory_star">*</span>
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 100 }}
                                        overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                        Search by either hitting the enter button or clicking on the search icon.
                                    </Tooltip>}
                                    >
                                        <small className="filedinfomsg"><i className="bi bi-info-circle-fill"></i></small>
                                    </OverlayTrigger>
                                </Form.Label>
                                <div className="serachBoxWIth">
                                    <Form.Control 
                                        type="text" 
                                        value={ApplicationState?.PINCode} 
                                        onChange={(e) => PinCOdeSearchFunction(e)}
                                        onKeyUp={(e) => CheckValid(e.target.value, {type: 'PINCode', error: seterrorPINCode})}
                                        onKeyDown={(e) => {
                                            EmptySpaceFieldValid(e);
                                            onEnterPincodeSearch(e)
                                        }}
                                        disabled={commonLoader}
                                        maxLength={6}
                                        placeholder="Enter PIN Code" 
                                    />
                                    <Button className="searchbtn" onClick={() => SearchPincode()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                            <path d="M21 21l-6 -6"></path>
                                        </svg>
                                    </Button>
                                    {ErrorMessageFromReddux?.API === "pin_code_search" && (<>
                                        <div className="pincode_error">
                                            {ErrorMessageFromReddux?.Error !== "" ? (
                                                <i className="bi bi-x-circle-fill" onClick={() => setApplicationState({...ApplicationState, PINCode: ""})} style={{ color: "red" }}></i>
                                            ) : (<i className="bi bi-check-circle-fill" style={{ color: "#10b068" }}></i>)}
                                        </div>
                                    </>)}
                                    {commonLoader && (<Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>)}
                                </div>
                                {errorPINCode !== "" &&<span className="error_medotory">{errorPINCode}</span>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>City</Form.Label>
                                <div className="defaultvalueField">
                                    {ErrorMessageFromReddux?.Error === "" ? searchPincodeList?.city?.district_name : ""}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>State</Form.Label>
                                <div className="defaultvalueField">
                                    {ErrorMessageFromReddux?.Error === "" ? searchPincodeList?.state?.state_name : ""}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3 " >
                                <Form.Label>POC Name<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={ApplicationState?.POCName} 
                                    onChange={(e) => setApplicationState({...ApplicationState, POCName: e.target.value})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'POCName', error: seterrorPOCName})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    disabled={UploadFileLoader}
                                    placeholder="Enter POC Name" 
                                />
                                {errorPOCName !== "" &&<span className="error_medotory">{errorPOCName}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3 " >
                                <Form.Label>POC Number<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="number" 
                                    value={ApplicationState?.POCNumber} 
                                    className="inputBoxs"
                                    onChange={(e) => setApplicationState({...ApplicationState, POCNumber: e.target.value.replace(/^0/, "")})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'POCNumber', error: seterrorPOCNumber})}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    disabled={UploadFileLoader}
                                    placeholder="Enter POC Number" 
                                    maxLength={25}
                                />
                                {errorPOCNumber !== "" &&<span className="error_medotory">{errorPOCNumber}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Address Line 1<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="text" 
                                    as="textarea" rows={3}
                                    value={ApplicationState?.Address} 
                                    onChange={(e) => setApplicationState({...ApplicationState, Address: e.target.value})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'Address', error: seterrorAddress})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    placeholder="Enter Address" 
                                    disabled={UploadFileLoader}
                                />
                                {errorAddress !== "" &&<span className="error_medotory">{errorAddress}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Address Line 2(Optional)</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    as="textarea" rows={3}
                                    value={ApplicationState?.address2} 
                                    onChange={(e) => setApplicationState({...ApplicationState, address2: e.target.value})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'address2', error: seterrorAddress2})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    placeholder="Enter Address" 
                                    disabled={UploadFileLoader}
                                />
                                {errorAddress2 !== "" &&<span className="error_medotory">{errorAddress2}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>PAN Card</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={ApplicationState?.PANCard} 
                                    onChange={(e) => setApplicationState({...ApplicationState, PANCard: e.target.value.toUpperCase()})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'PANCard', error: seterrorPANCard})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    placeholder="Enter PAN Card" 
                                    disabled={UploadFileLoader}
                                />
                                {errorPANCard !== "" &&<span className="error_medotory">{errorPANCard}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>PAN Image</Form.Label>
                                {(ImageDisplay !== undefined && ImageDisplay !== "") ? (<>{
                                    ImageDisplay?.name?.split(".").pop() === "pdf" ? (<div className="pdffileviewo">
                                        <i className="bi bi-filetype-pdf"></i> {ImageDisplay?.name}
                                        <span className="closeIcon" onClick={() => RemoveUploadImage()}></span>
                                </div>) : (
                                    <div className="imageuploads">
                                        <Image src={ImageDisplay?.url} onError={(e) => e.target.src = ProductDefaultImage} alt="charity avatar" />
                                        <span className="closeIcon" onClick={() => RemoveUploadImage()}></span>
                                    </div>)}
                                </>) : (<Form.Control type="file" onChange={(e) => UploadImage(e)} accept=".png, .jpg, .pdf" />)}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>GSTIN<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={ApplicationState?.GSTIN} 
                                    onChange={(e) => GSTINvalid(e?.target?.value)}
                                    onKeyUp={(e) => GSTINvalidKeyUp(e?.target?.value)}
                                    onKeyDown={EmptySpaceFieldValid}
                                    disabled={UploadFileLoader}
                                    placeholder="Enter GSTIN" 
                                />
                                {errorGSTIN !== "" &&<span className="error_medotory">{errorGSTIN}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>GSTIN Image</Form.Label>
                                {(GSTINImageDisplay !== undefined && GSTINImageDisplay !== "") ? (<>{
                                    GSTINImageDisplay?.name?.split(".").pop() === "pdf" ? (<div className="pdffileviewo">
                                        <i className="bi bi-filetype-pdf"></i> {GSTINImageDisplay?.name}
                                        <span className="closeIcon" onClick={() => RemoveGSTINImage()}></span>
                                </div>) : (
                                    <div className="imageuploads">
                                        <Image src={GSTINImageDisplay?.url} onError={(e) => e.target.src = ProductDefaultImage} alt="charity avatar" />
                                        <span className="closeIcon" onClick={() => RemoveGSTINImage()}></span>
                                    </div>)}
                                </>) : (<Form.Control type="file" onChange={(e) => UploadGSTINImage(e)} accept=".png, .jpg, .pdf" />)}
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Region<span className="mendatory_star">*</span></Form.Label>
                                <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                                    <Select 
                                        onChange={(e) => RegionSelectFCT(e)} 
                                        defaultValue={defaultRegionOption} 
                                        isClearable 
                                        options={optionsRegion}
                                        className="godown-select-container" 
                                        classNamePrefix="godown-select"
                                        styles={customStyles}
                                    />
                                    {errorRegion !== "" && (<span className="error_medotory">{errorRegion}</span>)}
                                </div>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group className="mb-3" >
                                <Form.Label>Status</Form.Label>
                                <div className="ToggleMainWrap">
                                    <CommonToggle 
                                        valueToggle={ApplicationState?.Status}
                                        setValueToggle={() => setApplicationState({...ApplicationState, Status: !ApplicationState?.Status})}
                                        name={""}
                                    />
                                    {ApplicationState?.Status ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12" style={{ textAlign: "right" }}>
                            <Button className="formbtn" onClick={() => CreateWareHouseFct()} style={{ width: "auto" }} variant="primary" >
                                {LocationRoute === "edit-warehouse" ? "Update" : "Add"}
                            </Button>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </div>
    </section>)
}

export default CreateWarehouse;