/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccessToken, getDeviceId } from "../redux/actions/adminActions";
import { useNavigate } from "react-router-dom";
import { FRANCHISE_ORDERSREPORT_URL, LOGIN_URL } from "./Shared/constant";

const SetLogin =() => {
    const Dispatch = useDispatch();
    const Navigate = useNavigate();
    const { accessToken } = useSelector((state) => state.adminReducers);
    
    useEffect(() => {
        Dispatch(getAccessToken(localStorage.getItem("access_token")));
        if(accessToken !== "") {
            Navigate(FRANCHISE_ORDERSREPORT_URL);
        } else {
            Navigate(LOGIN_URL);
        };
    }, []);

    return(<div>

    </div>)
}

export default SetLogin;