/* eslint-disable */
import React, { useState } from "react";
import { Form, Button, Image, Spinner } from 'react-bootstrap';
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import { DASHBOARD_URL, SET_LOGIN_URL } from "../Shared/constant";
import { useNavigate } from "react-router-dom";
import LoginAPI from "../../Api/LoginAPI";
import GuestLayout from "../Shared/GuestLayout";
import { useDispatch, useSelector } from "react-redux";
import { getSelfDetails, getAccessToken, checkLogin, getUserId, getDeviceId, setGetMagicCode } from "../../redux/actions/adminActions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LogoImage from "../../assets/img/logo.png";
import getMagiccodeAPI from "../../Api/getMagiccodeAPI";

const Login = () => {
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    // const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; // eslint-disable-line
    const PasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@?!])[A-Za-z\d@?!]{8,}$/; // eslint-disable-line
    const [ PasswordShow, setPasswordShow ] = useState(false);
    const [ LoaderStart, setLoaderStart ] = useState(false);
    const Navigate = useNavigate();
    const dispatch = useDispatch();
    const { device_id, selfInfo } = useSelector((state) => state.adminReducers);
    
    // generate unique id
    function uuidv4() { 
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    
    // error message
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorPassword, setErrorPassword ] = useState("");

    // on enter login
    const onEnterLogin = (e) => {
        const code = e.which || e.keyCode;
        if(code === 13) {
            SignInButton();
        };
    };
    
    // sign in function
    const SignInButton = async () => {
        if(email !== "" && password !== "") {
            // if(EmailRegex.test(email) === true && PasswordRegex.test(password) === true) {
            if(EmailRegex.test(email) === true) {
                var DeviceID = uuidv4();
                setLoaderStart(true);
                const jsonData = JSON.stringify({ email: email, password: password, device_type: "web", device_id: DeviceID});
                const response = await LoginAPI(jsonData);
                if(response?.status === 200){
                    toast.success(response.msg);
                    dispatch(getSelfDetails(response?.data));
                    
                    const payloadMagic = {
                        device_id: DeviceID,
                        device_type:"web"
                    }
                    const response2 = await getMagiccodeAPI(response?.data?.access_token, payloadMagic);
                    if(response2?.status === 200){
                        
                        dispatch(setGetMagicCode(response2?.data));
                        dispatch(getDeviceId(DeviceID));
                        dispatch(getUserId(response?.data?.user?.id));
                        localStorage.setItem("access_token", response?.data?.access_token);
                        localStorage.setItem("device_id",  response?.data?.device_id);
                        setLoaderStart(false);
                        
                        setTimeout(() => {
                            window.location.replace(process.env.REACT_APP_BASE_URL+`user/set_login/${response2?.data}/${response2?.device_id}/web/${location?.host}`);
                        }, 500);
                    };
                 } else {
                    setLoaderStart(false);
                    toast.error(response.error);
                 };
            } else {
                CheckValid(email, {type: 'email', error: setErrorEmail });
                CheckValid(password, {type: 'password', error: setErrorPassword });
            }
        } else {
            CheckValid(email, {type: 'email', error: setErrorEmail });
            CheckValid(password, {type: 'password', error: setErrorPassword });
        };
    };

    // clear form
    const clearForm = () => {
        setEmail("");
        setErrorEmail("");
        setErrorPassword("");
        setPassword("");
    };

    return(<GuestLayout>
        <div className="loginWrapper">
        <div className="loginMainblog" id="loginMainblog">
            <h1 className="logoheader">
                <Image src={LogoImage} alt="logo" />
            </h1>
            <div className="formloginset">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    {/* <Form.Label>Email address</Form.Label> */}
                    <div className="login_input_icons">
                        <i className="bi bi-envelope-at"></i>
                        <input type="email" className="d-none" />
                        <Form.Control type="email" value={email} onKeyUp={(e) => CheckValid(e.target.value, {type: 'email', error: setErrorEmail })} onChange={(e) => setEmail(e.target.value)} onKeyDown={(e) => {onEnterLogin(e); EmptySpaceFieldValid(e)}} placeholder="Enter email address" autoComplete="new-email" disabled={LoaderStart} />
                    </div>
                    {errorEmail && <div className="danger-colorset">{errorEmail}</div>}
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicPassword">
                    {/* <Form.Label>Password</Form.Label> */}
                    <div className="login_input_icons">
                        <i className="bi bi-person-lock"></i>
                        <div className="passwordfiled">
                            <input type="password" className="d-none" />
                            <Form.Control type={PasswordShow ? "text" : "password"} value={password} onKeyUp={(e) => CheckValid(e.target.value, {type: 'password', error: setErrorPassword})} onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => {onEnterLogin(e); EmptySpaceFieldValid(e)}} placeholder="Enter password" autoComplete="new-password"  disabled={LoaderStart}/>
                            <div className="showpassbtn" onClick={() => setPasswordShow(!PasswordShow)}>
                                {PasswordShow ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path></svg>) :
                                (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg>)}
                            </div>
                        </div>
                    </div>
                    {errorPassword && <div className="danger-colorset">{errorPassword}</div>}
                </Form.Group>
                <Button onClick={SignInButton} id="signInbtn" className="signInbtn"  disabled={LoaderStart}>
                    {LoaderStart ? (<Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>) : "Sign In"}
                </Button>
            </div>
        </div>
    </div>
    </GuestLayout>)
}

export default Login;