/* eslint-disable */
import React, { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import ReactSelect from 'react-select';
import PropTypes from "prop-types";

// function uuidv4() {
//     return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
//       (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
//     );
// }

export default function SelectReact(props) {
    const [inputState, setInputState] = useState(props?.inputState)
    
    const callSelectedValue = () =>{
        if(props?.isMulti){
            return null;
        }else{
            const data = props?.MainOptions?.find((item)=>item?.value == props?.value);
            if(data){
                return data;
            }else{
                return null;
            }
        }
    }
    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          fontSize: '13px', // Adjust the font size as per your requirement
        }),
    };
  
    const selectValue = useMemo(callSelectedValue, [props?.value])

    const onChange = (e) =>{
        if(!props?.isMulti){
            return props?.onChange({
                target:{
                    name:props?.name,
                    value: e?.value ? e?.value :null,
                }
            }, e)
        }else{

        }
    }
    const onInputChange = (e, item) =>{
        setInputState(e);
        props?.onInputChange(e, item)
    }
    // const id = useMemo(uuidv4);
    const formGroup = () =>{
        const FormSelect = () =>{
            return(
                <ReactSelect
                    onChange={onChange} 
                    defaultValue={selectValue} 
                    options={props?.options}
                    className={props?.className}
                    classNamePrefix="godown-select"
                    value={selectValue}
                    styles={customStyles}
                    placeholder={props?.placeholder}
                    name={props?.name}
                    isClearable={props?.isClearable}
                    isMulti={props?.isMulti}
                    isDisabled={props?.disabled}
                    onInputChange={onInputChange}
                    inputValue={inputState}
                />
            )
        }
        return(
            <div className={`selectMultiselect ${props?.classNameFormGroup} selectMultiselectCategory`} id="selectMultiselectBrand">
                {FormSelect()}
            </div>
        )
    }
  return (
    <Form.Group  className={props?.classNameForm} >
        {props?.label && (
            <Form.Label>{props?.label} {props?.required && (<span className="mendatory_star">*</span>)}</Form.Label>
        )}
        {formGroup()}
        {props?.error !== "" && <span className="error_medotory">{props?.error}</span>}

    </Form.Group>
  )
}
SelectReact.propTypes = {
    label: PropTypes.any,
    className: PropTypes.any,
    classNameForm: PropTypes.any,
    classNameFormGroup: PropTypes.any,
    name: PropTypes.any,
    error: PropTypes.any,
    placeholder: PropTypes.any,
    options: PropTypes.array,
    value: PropTypes.any,
    inputValue: PropTypes.any,
    isMulti: PropTypes.bool,
    required: PropTypes.bool,
    isClearable: PropTypes.any,
    disabled: PropTypes.any,
    onChange: PropTypes.func,
    onInputChange: PropTypes.func,
}
SelectReact.defaultProps = {
    label: "",
    className: "",
    classNameForm: "",
    classNameFormGroup: "",
    name: "",
    error: "",
    placeholder: "Select...",
    options: [],
    value: null,
    inputValue: "",
    isMulti: false,
    required: false,
    isClearable: false,
    disabled: false,
    onChange: ()=>{},
    onInputChange: ()=>{},
}