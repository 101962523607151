/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { WebSocketContext } from "../../App";
import { useLocation, Link } from "react-router-dom";
import { DASHBOARD_URL, ACCOUNT_URL, STOCK_LEVEL_URL, ACCOUNT_LEDGER_REPORT, FRANCHISE_ORDERSREPORT_URL, FRANCHISE_DISPATCH_URL, FRANCHISE_DISPATCH_PENDING_URL, FRANCHISE_PENDING_DELIVERY_URL, STORE_DETAILS_REPORT_URL } from "./constant";
import OrderIcon from "./../../assets/img/handshake.png";
import OrderActiveIcon from "./../../assets/img/Franchise-active.png";
import ResponsiveSidebar from "../Common/ResponsiveSidebar";

const AuthLayout = ({children}) => {
    const [divWidth, setDivWidth] = useState(null);
    const { ResponsiveWidth } = useContext(WebSocketContext);
    const PageName = useLocation()?.pathname?.split("/")[1]?.replaceAll("-", " ");
    const LocationURL = useLocation().pathname;
    const [ MobileSidebar, setMobileSidebar ] = useState({
        open: false,
        Url: "",
    });

    // page rendering then close responsive sidbar
    useEffect(() => {
        return() => {
            setMobileSidebar({
                ...MobileSidebar,
                open: false,
                Url: "",
            });
        }
    }, [ LocationURL ]);

    // main sidebar 
    const MainSidebar = (url) => {
        setMobileSidebar({
            ...MobileSidebar,
            open: !MobileSidebar?.open,
            Url: url,
        });
    };

    
    return(<section className="adminmain">
        <Header 
            sidebarWidth={divWidth} 
            MobileSidebar={MobileSidebar} 
            setMobileSidebar={setMobileSidebar} 
        />
        {ResponsiveWidth > 700 && (<Sidebar setDivWidth={setDivWidth} />)}
        <div className={"panelwrapper active"}>
            <div className="panelContentwrapper">
                {(ResponsiveWidth < 700 && LocationURL !== STOCK_LEVEL_URL && LocationURL !== ACCOUNT_LEDGER_REPORT && LocationURL !== STORE_DETAILS_REPORT_URL) && (<h4 className="responsive_header_title">{PageName}</h4>)}
                {children}
            </div>
        </div>
        {ResponsiveWidth < 700 && (<ul className="responsive_bottom_footer">
            <li><Link className={LocationURL === DASHBOARD_URL ? "active" : ""} to={DASHBOARD_URL}><i className={LocationURL === DASHBOARD_URL ? "bi bi-house-fill" : "bi bi-house"}></i> Home</Link></li>
            <li><Link onClick={() => MainSidebar("order_sidebar")}><img src={((MobileSidebar?.open && MobileSidebar?.Url === "order_sidebar") || (LocationURL === FRANCHISE_ORDERSREPORT_URL || LocationURL === FRANCHISE_DISPATCH_URL || LocationURL === FRANCHISE_DISPATCH_PENDING_URL || LocationURL === FRANCHISE_PENDING_DELIVERY_URL)) ? OrderActiveIcon : OrderIcon} alt="order icon" /> Order</Link></li>
            <li><Link className={LocationURL === STOCK_LEVEL_URL ? "active" : ""} to={STOCK_LEVEL_URL}><i className="bi bi-shop-window"></i> Inventory</Link></li>
            <li><Link className={LocationURL === ACCOUNT_URL ? "active" : ""} to={ACCOUNT_URL}><i className={LocationURL === ACCOUNT_URL ? "bi bi-person-fill" : "bi bi-person"}></i> Account</Link></li>
        </ul>)}

        {MobileSidebar?.open && (<ResponsiveSidebar MobileSidebar={MobileSidebar} setMobileSidebar={setMobileSidebar} />)}
    </section>)
}

export default AuthLayout;